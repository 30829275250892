<template>
  <div>
    <van-cell title="选择日期" :value="date.val" @click="date.show=true" is-link/>
    <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true" />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-collapse v-model="actName" accordion>
      <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" style="padding: 10px 0px;">
        <van-collapse-item v-for="item in lst.da" :key="item.ID" :name="item.ID">
          <div slot="title">
            <p>
              <router-link :to="{path:'/safe/rec?dts='+item.CREATED_DT+'&dte='+item.CREATED_DT}">
                【{{item.CREATED_DT | datetime('YYYY-MM-DD')}}】
              </router-link>
            </p>
            <span style="margin: 0 10px">总项：{{item.CNT}}</span>
            <span style="margin: 0 10px">正常：{{item.NOR}}</span>
            <span style="margin: 0 10px; color: red" v-if="item.NUL>0">未检：{{item.NUL}}</span>
            <span style="margin: 0 10px; color: red" v-if="item.ERR>0">异常：{{item.ERR}}</span>
          </div>
          <div slot="default">
            <p style="color:red;" v-if="item.ERR>0">异常项：{{item.ER_NAME}}</p>
            <p v-if="item.NUL>0">未检项：{{item.UN_NAME}}</p>
            <p v-else style="color: #07c160">全部完成</p>
          </div>
        </van-collapse-item>
      </van-list>
    </van-collapse>
  </div>
</template>
<style scoped>
</style>
<script>
import moment from "moment";
export default {
  name: "index",
  data() {
    return {
      sea:{
        PAGE_SIZE:10,
        PAGE_INDEX:-1,
        DTS:moment().add(-7, 'days').format('YYYY-MM-DD'),
        DTE:moment().format('YYYY-MM-DD'),
        KEY:"",
      },
      lst:{
        da:[],
        loading:false,
        finish:false
      },
      date:{
        show:false,
        val:"",
        minDate:new Date(moment().add(-180, 'd'))
      },
      actName:""
    }
  },
  created() {
    this.filter();
  },
  methods:{
    filter(){
      this.lst.da.length=0;
      this.lst.finish=false;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/SAFE/RepApi/GetList",
        data:this.sea,
        completed(its,n){
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    },
    dateSel(d){
      this.sea.DTS=moment(d[0]).format('YYYY-MM-DD');
      this.sea.DTE=moment(d[1]).format('YYYY-MM-DD')
      this.date.show=false;
      this.date.val=this.sea.DTS+' 至 '+this.sea.DTE;
      this.filter();
    }
  }
}
</script>

